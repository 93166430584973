/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import BlockDefault from 'components/elements/BlockDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import Plaatjie from '@ubo/plaatjie'
import { USP } from 'components/Identity'

const Video = styled.iframe`
  @media (min-width: 768px) {
    width: 560px;
    height: 315px;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 250px;
  }
`

const UspsContent = styled(ParseContent)`
  & img {
    border-radius: 10px;
    overflow: hidden;
    margin-right: 2.5rem;
    border: 1px solid #f0f0f0;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
  }
`

const Service1 = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  border-radius: 35px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  height: 100%;

  & h3 {
    font-size: ${({ theme }) => theme.font.size.xm};
    color: ${({ theme }) => theme.color.light};
  }
`

const Service1Image = styled(Plaatjie)`
  width: 100%;
  height: 150px;

  & img {
    object-fit: cover;
    object-position: 0 0;
  }
`

const ActivateAccount = styled.section`
  background-color: #f0f0f0;
`

const ActivateAccountContent = styled(ParseContent)`
  text-align: center;

  & p,
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: center;
  }
`

const Service = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  height: 100%;
  position: relative;
`

const ServiceContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  color: #ffffff;
  z-index: 1;
  height: 100%;

  & [role='button'] > div {
    padding: 7px 15px;
  }
`

const ServiceImage = styled(Plaatjie)`
  position: absolute;

  ${(props) =>
    props.full
      ? css`
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.25;
        `
      : css`
          bottom: -15px;
          right: -15px;
          width: 125px;
          height: 125px;
          opacity: 0.35;
        `};

  z-index: 0;

  & img {
    object-fit: cover;
    object-position: 0 0;
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
    pages,
    site,
  },
}) => {
  const [infoPageId, setInfoPageId] = React.useState(null)
  const [accountEmail, setAccountEmail] = React.useState(null)
  const [indexOpen, setIndexOpen] = React.useState(null)
  const [status, setStatus] = React.useState(null)
  const activateAccountRef = React.useRef(null)

  const setOpen = (index) => {
    setIndexOpen(index === indexOpen ? null : index)
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)

      if (params) {
        setInfoPageId(params.get('infoId'))
        setAccountEmail(params.get('email'))
      }
    }
  }, [])

  const submitAccount = async () => {
    window.scrollTo(0, activateAccountRef.current.offsetTop - 150)

    setStatus('loading')

    try {
      const request = await fetch(
        `${site.meta.wpUrl}/wp-json/moovaz/v1/account`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: accountEmail,
          }),
        }
      )

      const response = await request.json()

      if (response && response.response.code === 200) {
        setStatus('done')
      } else {
        setStatus('error')
      }
    } catch (error) {
      setStatus('error')
    }
  }

  if (!infoPageId) {
    return null
  }

  const infoPage = pages.edges.find(
    (page) => page.node.wordpress_id === Number(infoPageId)
  )

  if (!infoPage) {
    return null
  }

  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <ActivateAccount>
        <div className="container py-5 mb-5">
          <div className="row py-5">
            <div className="col-lg-2" />
            <div className="col-lg-8 pt-5">
              <BlockDefault>
                <ParseContent content={infoPage.node.acf.form_send.title} />
                <ParseContent
                  className="mt-4"
                  content={infoPage.node.acf.form_send.description}
                />
              </BlockDefault>
            </div>
          </div>
        </div>
      </ActivateAccount>

      <section>
        <div className="container py-5 my-5">
          <ParseContent content={acf.youtube_1.description} />

          <div className="row justify-content-center mt-5">
            <div className="col-md-11 col-lg-9 col-xl-8">
              <BlockDefault className="text-center">
                <Video
                  className="mx-auto"
                  src={acf.youtube_1.youtube_url}
                  title="Moovaz"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </BlockDefault>
            </div>
          </div>
        </div>
      </section>

      <ActivateAccount>
        <div className="container my-5 py-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <BlockDefault>
                <UspsContent
                  className="py-lg-4 px-lg-4"
                  content={acf.features.description}
                />
              </BlockDefault>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mt-lg-5 ml-lg-n5">
                {acf.features.items.map(({ title, description }, index) => (
                  <USP
                    key={index}
                    title={title}
                    description={description}
                    index={index}
                    setOpen={setOpen}
                    indexOpen={indexOpen}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ActivateAccount>

      <section>
        <div className="container my-5 py-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <ParseContent content={acf.services_2.description} />
            </div>
          </div>

          <div className="row mt-5">
            {acf.services_2.items.map((item) => (
              <div className="col-6 col-md-4 col-lg mb-4" key={item.title}>
                <Service1>
                  <Service1Image image={item.image} />
                  <div className="p-3">
                    <h3>{item.title}</h3>
                  </div>
                </Service1>
              </div>
            ))}
          </div>

          <div className="mt-5 text-center">
            <ParseContent content={acf.services_2.description_outro} />
          </div>
        </div>
      </section>

      {/* <ActivateAccount>
        <div className="container py-5 my-5" ref={activateAccountRef}>
          <ActivateAccountContent
            className="text-center"
            content={acf.activate_1.description}
          />
          <div className="mt-4 text-center">
            <AccountActivation
              status={status}
              submitAccount={submitAccount}
              buttonText={acf.activate_1.button_text}
              activate={acf.activate}
            />
          </div>
        </div>
      </ActivateAccount> */}

      <section>
        <div className="container py-lg-5 my-5">
          <ParseContent content={acf.services.description} />

          <div className="row mt-4">
            {acf.services.services.map(
              ({ title, description, image }, index) => {
                const full =
                  image.media_details.width / image.media_details.height > 1.1

                return (
                  <div key={index} className="col-sm-6 col-lg-4 mb-4">
                    <Service>
                      <ServiceImage image={image} alt={title} full={full} />

                      <ServiceContent>
                        <ParseContent content={`<h3>${title}</h3>`} />
                        <div className="mt-4">
                          <ParseContent content={description} />
                        </div>

                        {/* <div className={`mt-3 ${full ? 'text-center' : ''}`}>
                          <AccountActivation
                            status={status}
                            submitAccount={submitAccount}
                            small
                            buttonText={acf.activate_1.button_text}
                            activate={acf.activate}
                          />
                        </div> */}
                      </ServiceContent>
                    </Service>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </section>

      {/* <ActivateAccount>
        <div className="container py-5 my-5">
          <ActivateAccountContent
            className="text-center"
            content={acf.activate_2.description}
          />
          <div className="mt-4 text-center">
            <AccountActivation
              status={status}
              submitAccount={submitAccount}
              buttonText={acf.activate_2.button_text}
              activate={acf.activate}
            />
          </div>
        </div>
      </ActivateAccount> */}
    </Layout>
  )
}

const AccountActivation = ({
  status,
  submitAccount,
  small = false,
  buttonText,
  activate,
}) => (
  <>
    {!small && status === 'error' && (
      <div>
        <ParseContent content={activate.error} />
      </div>
    )}

    {!small && status === 'loading' && (
      <div>
        <ParseContent content={activate.loading} />
      </div>
    )}

    {!small && status === 'done' && !small && (
      <div className="row justify-content-center">
        <div className="col-6">
          <BlockDefault>
            <ParseContent content={activate.done} />
          </BlockDefault>
        </div>
      </div>
    )}

    {!status && (
      <ButtonDefault
        small={small}
        isCustom
        onClick={() => {
          submitAccount()
        }}
      >
        {buttonText}
      </ButtonDefault>
    )}
  </>
)

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    site {
      meta: siteMetadata {
        wpUrl: siteWpUrl
      }
    }

    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        features {
          description
          items {
            title
            description
          }
        }
        activate_1 {
          description
          button_text
        }
        services {
          description
          services {
            title
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
              media_details {
                height
                width
              }
            }
          }
        }
        activate_2 {
          description
          button_text
        }
        activate {
          error
          loading
          done
        }
        youtube_1 {
          description
          youtube_url
          button {
            title
            url
          }
        }

        services_2 {
          description
          items {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 300)
                }
              }
            }
          }
          description_outro
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }

    pages: allWordpressPage {
      edges {
        node {
          wordpress_id

          acf {
            form_send {
              title
              description
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
